import React, {useState} from 'react';
import styled from 'styled-components';
import {Headline, Body, Title1} from '../../../../../components/Typography';
import appConfig from '../../../../../../app-config';

const Wrapper = styled.div`
  margin: auto;
  margin-top: 150px;
  display: grid;
  grid-template-columns: 264px auto;
  margin-left: 5%;
  margin-right: 5%;

  @media (max-width: 1200px) {
    grid-template-columns: auto;
    grid-template-rows: auto;
  }

  @media (max-width: 786px) {
    margin-left: 20px;
    margin-right: 20px;
    margin-top: 200px;
    justify-items: center;
  }
`;

const Title1Wrapper = styled(Title1)`
  justify-self: left;
  font-weight: 600;

  :after {
    content: '';
    display: block;
    width: 170px;
    padding-top: 2px;
    border-bottom: solid
      ${props => props.theme.categoriesBarSelectedBorderColor};
  }

  @media (max-width: 1200px) {
    margin-bottom: 20px;
  }
`;

const ItemContainer = styled.div`
  padding: 0 5px;
`;

const Card = styled.div`
  display: grid;
  grid-template-columns: 40px 1fr;
  margin-bottom: 32px;
  grid-gap: 15px;
  align-items: center;
`;

const Icon = styled.i`
  font-size: 32px;
  color: ${props =>
    props.theme.howItWorksIconColor ||
    props.theme.categoriesBarSelectedBorderColor};
  vertical-align: middle;
`;

const CardText = styled.div``;

const HowItWorks = () => {
  return (
    <Wrapper>
      <Title1Wrapper>How It Works</Title1Wrapper>
      <ItemContainer>
        {appConfig.howItWorksContent.map(item => (
          <Card>
            {item.image ? (
              <img src={item.image} />
            ) : (
              <Icon className={item.icon}></Icon>
            )}
            <CardText>
              <Headline id="OfferList-ItemHeadline" fontWeight="bold">
                {item.title}
              </Headline>
              {typeof item.description === 'string' ? (
                <Body
                  color={appConfig.howItWorksDescriptionFontColor}
                  id="OfferList-ItemDescription"
                >
                  {item.description}
                </Body>
              ) : (
                item.description.map((desc, index) => (
                  <Body key={index}>{desc}</Body>
                ))
              )}
            </CardText>
          </Card>
        ))}
      </ItemContainer>
    </Wrapper>
  );
};

export default HowItWorks;
