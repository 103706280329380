import React, {useEffect} from 'react';
import {connect} from 'react-redux';
import {navigatedToHowItWorksTab} from '../../offersActions';
import HeaderComponent from '../components/HeaderComponent';
import HowItWorksPlain from './components/HowItWorksPlain';
import HowItWorksIcons from './components/HowItWorksIcons';
import HowItWorksList from './components/HowItWorksList';
import IQFoodHowItWorks from './components/ClientHowItWorks/IQFoodHowItWorks';
import appConfig from '../../../../../app-config';

const HowItWorksContainer = ({navigatedToHowItWorksTab}) => {
  useEffect(() => {
    navigatedToHowItWorksTab();
  }, []);

  if (appConfig.appSource === 'iQ') {
    return (
      <div>
        <HeaderComponent />
        <IQFoodHowItWorks />
      </div>
    );
  }

  return (
    <div>
      <HeaderComponent />
      {appConfig.howItWorksPageType === 'plain' && <HowItWorksPlain />}
      {appConfig.howItWorksPageType === 'icons' && <HowItWorksIcons />}
      {appConfig.howItWorksPageType === 'list' && <HowItWorksList />}
    </div>
  );
};

const actions = {navigatedToHowItWorksTab};

export default connect(null, actions)(HowItWorksContainer);
