import React from 'react';
import styled from 'styled-components';
import {Title1} from '../../../../../components/Typography';
import appConfig from '../../../../../../app-config';

const Wrapper = styled.div`
  margin: auto;
  font-family: ${props => props.theme.primaryFontFontFamily};
  margin-top: 150px;
  display: grid;
  grid-template-columns: 264px 500px;
  margin-left: 5%;
  margin-right: 5%;
  margin-bottom: 200px;

  @media (max-width: 1200px) {
    grid-template-columns: auto;
    grid-template-rows: auto;
  }

  @media (max-width: 786px) {
    margin-left: 20px;
    margin-right: 20px;
    margin-top: 200px;
    justify-items: center;
  }
`;

const Title1Wrapper = styled(Title1)`
  justify-self: left;
  font-weight: 600;

  :after {
    content: '';
    display: block;
    width: 170px;
    padding-top: 2px;
    border-bottom: solid
      ${props => props.theme.categoriesBarSelectedBorderColor};
  }

  @media (max-width: 1200px) {
    margin-bottom: 20px;
  }
`;

const ItemContainer = styled.div`
  padding: 0 5px;
  display: grid;
  grid-gap: 15px;
  margin-top: 20px;
`;

const NoTitleParagraph = styled.p`
  font-size: 16px;
  color: #737a87;
  line-height: 1.38;
  margin-bottom: 25px;
`;

const Title = styled.p`
  font-size: 19px;
  font-weight: 600;
  color: #000;
  margin-bottom: 7px;
`;

const ListElement = styled.p`
  font-size: 16px;
  color: #737a87;
  line-height: 1.38;
`;

const HowItWorks = () => {
  return (
    <Wrapper>
      <Title1Wrapper id="HowItWorks">How It Works</Title1Wrapper>
      <ItemContainer>
        {appConfig.howItWorksContent.firstParagraph.length ? (
          <NoTitleParagraph>
            {appConfig.howItWorksContent.firstParagraph}
          </NoTitleParagraph>
        ) : null}
        {appConfig.howItWorksContent.content.map(text => {
          return (
            <div>
              <Title id="HowItWorks-Title">{text.title}</Title>
              {text.elements.map(element => {
                return <ListElement>- {element}</ListElement>;
              })}
            </div>
          );
        })}
      </ItemContainer>
    </Wrapper>
  );
};

export default HowItWorks;
