import React from 'react';
import {connect} from 'react-redux';
import Page404 from './404';
import HowItWorksContainer from '../modules/Offers/OffersList/HowItWorks/HowItWorksContainer';
import appConfig from '../../app-config';

const HowItWorksIndex = ({isAuthenticated}) => {
  if (isAuthenticated && appConfig.isHowItWorksEnabled) {
    return <HowItWorksContainer />;
  } else {
    return <Page404 />;
  }
};

const mapStateToProps = state => ({
  isAuthenticated: state.authReducer.isAuthenticated,
});

export default connect(mapStateToProps)(HowItWorksIndex);
