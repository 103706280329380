import React from 'react';
import styled from 'styled-components';
import {Title1} from '../../../../../../components/Typography';
import appConfig from '../../../../../../../app-config';

const Wrapper = styled.div`
  margin: auto;
  margin-top: 150px;
  display: grid;
  grid-template-columns: 264px 440px;
  margin-left: 5%;
  margin-right: 5%;

  @media (max-width: 786px) {
    grid-template-columns: auto;
    grid-template-rows: auto;
    margin-left: 20px;
    margin-right: 20px;
    margin-top: 200px;
    justify-items: center;
  }

  @media (max-width: 480px) {
    grid-template-columns: auto;
  }
`;

const Title1Wrapper = styled(Title1)`
  justify-self: left;
  font-weight: 600;

  :after {
    content: '';
    display: block;
    width: 170px;
    padding-top: 2px;
  }

  @media (max-width: 1200px) {
    margin-bottom: 20px;
  }
`;

const ItemContainer = styled.div`
  padding: 0 5px;
  display: grid;
  grid-gap: 21px;
  width: 440px;

  @media (max-width: 480px) {
    width: auto;
  }
`;

const NoTitleParagraph = styled.p`
  font-size: 16px;
  color: #737a87;
  line-height: 1.38;
  margin-bottom: 25px;
`;

const Title = styled.p`
  font-size: 16px;
  font-weight: 300;
  // text-transform: lowercase;
  color: #58595b;
  margin-bottom: 11px;
  border-bottom: 1px solid #bcbec0;
`;

const Card = styled.div`
  display: grid;
  grid-template-columns: 50px 342px;
  grid-gap: 25px;
  align-items: start;

  @media (max-width: 480px) {
    grid-template-columns: 50px auto;
  }
`;

const Icon = styled.i`
  font-size: 32px;
  color: ${props => props.theme.categoriesBarSelectedBorderColor};
  display: table-cell;
  vertical-align: top;
`;

const DescriptionText = styled.p`
  font-size: 16px;
  font-weight: 300;
  line-height: 1.38;
  color: #58595b;
`;

const UnorderedList = styled.ul`
  margin-bottom: 0;
`;

const ListElement = styled.li`
  font-size: 16px;
  font-weight: 300;
  line-height: 1.38;
  color: #58595b;
  margin-top: 11px;
`;

const IQFoodHowItWorks = () => {
  return (
    <Wrapper>
      <Title1Wrapper id="HowItWorks">How It Works</Title1Wrapper>
      <ItemContainer>
        {appConfig.howItWorksContent.firstParagraph.length ? (
          <NoTitleParagraph>
            {appConfig.howItWorksContent.firstParagraph}
          </NoTitleParagraph>
        ) : null}
        {appConfig.howItWorksContent.content.map(item => {
          return (
            <div>
              <Title id="HowItWorks-Title">{item.title}</Title>
              <Card>
                {item.image ? (
                  <img src={item.image} />
                ) : (
                  <Icon className={item.icon}></Icon>
                )}
                <div>
                  <DescriptionText>{item.elements.description}</DescriptionText>
                  <UnorderedList>
                    {item.elements.specifications.map(element => {
                      return <ListElement>{element}</ListElement>;
                    })}
                  </UnorderedList>
                </div>
              </Card>
            </div>
          );
        })}
      </ItemContainer>
    </Wrapper>
  );
};

export default IQFoodHowItWorks;
