import React, {useState} from 'react';
import styled from 'styled-components';
import {Headline, Body, Title1} from '../../../../../components/Typography';
import appConfig from '../../../../../../app-config';

const Wrapper = styled.div`
  margin: auto;
  margin-top: 150px;
  display: grid;
  grid-template-columns: 264px 500px;
  margin-left: 5%;
  margin-right: 5%;

  @media (max-width: 1200px) {
    grid-template-columns: auto;
    grid-template-rows: auto;
  }

  @media (max-width: 786px) {
    margin-left: 20px;
    margin-right: 20px;
    margin-top: 200px;
    justify-items: center;
  }
`;

const Title1Wrapper = styled(Title1)`
  justify-self: left;
  font-weight: 600;

  :after {
    content: '';
    display: block;
    width: 170px;
    padding-top: 2px;
    border-bottom: solid
      ${props => props.theme.categoriesBarSelectedBorderColor};
  }

  @media (max-width: 1200px) {
    margin-bottom: 20px;
  }
`;

const ItemContainer = styled.div`
  padding: 0 5px;
  display: grid;
  grid-gap: 15px;
  margin-top: 20px;
`;

const Icon = styled.i`
  margin-left: 5px;
`;

const BodyWrapper = styled(Body)`
  font-weight: 600;
`;

const HowItWorks = () => {
  return (
    <Wrapper>
      <Title1Wrapper id="HowItWorks">How It Works</Title1Wrapper>
      <ItemContainer>
        {appConfig.howItWorksContent.map(text => {
          if (text.title)
            return <Headline id="HowItWorks-Title">{text.title}</Headline>;
          if (text.line)
            return <BodyWrapper id="HowItWorks-Text">{text.line}</BodyWrapper>;
          if (text.finalLine)
            return (
              <BodyWrapper>
                {text.finalLine} <Icon className={text.icon} />
              </BodyWrapper>
            );
        })}
      </ItemContainer>
    </Wrapper>
  );
};

export default HowItWorks;
